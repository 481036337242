import { useNavigate, useParams } from "react-router-dom";
import { useBusinessContext } from "../../storage/context/BusinessContext";
import { useGeneralContext } from "../../storage/context/GeneralContext";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ResponsiveContainer } from "../../components/DefaultResponsiveContainer";
import { createMoMoPaymentIntent, getInvoiceById, getMoMoPaymentIntent } from "../../api/subscription-api";
import { Button, Container, Divider, Form, Message, Segment } from "semantic-ui-react";

function MoMoPaymentForm({ businessId, invoiceId }: any) {
    const { currentUser } = useGeneralContext();
    const [performedPayment, setPerformedPayment] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {
        data: invoiceData,
        isLoading: isInvoiceLoading,
    } = useQuery({
        queryKey: ["business-invoice", businessId, invoiceId],
        queryFn: async () => {
            if (!currentUser) return null;
            if (!invoiceId) return null;
            if (!businessId) return null;
            const idToken = await currentUser.getIdToken();
            return await getInvoiceById(businessId, invoiceId, idToken);
        },
        retry: false,
        enabled: !!currentUser
    });

    const handleSubmit = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        if (!currentUser) return;
        const idToken = await currentUser.getIdToken();
        if (!idToken) return;
        if (!invoiceId) return;
        if (!transactionId) return;
        setLoading(() => true);
        try {
            await createMoMoPaymentIntent(invoiceId, transactionId, idToken);
            navigate(`/payment/momo/${businessId}/${invoiceId}`);
        } finally {
            setLoading(() => false);
        }
    }, [currentUser, invoiceId, transactionId, businessId, navigate]);

    if (isInvoiceLoading) return <p>Loading...</p>;
    if (!invoiceData) return <p>Invoice not found</p>;

    const formatter = new Intl.NumberFormat('en-US', { style: "currency", currency: invoiceData?.currency || "RWF" });

    return (
        <Form
            onSubmit={handleSubmit}
            style={{
                width: "min(100%, 700px)",
                margin: "auto",
                padding: "1em",
            }}
            loading={loading}
        >
            <Container
                text
                style={{
                    width: "min(100%, 700px)",
                    margin: "2em auto",
                    borderWidth: "1px",
                    borderRadius: "5px",
                    overflowX: "auto",
                    padding: "1em",
                    backgroundColor: "#f9f9f9",
                }}
            >
                <Container
                    style={{
                        margin: "1em 0",
                    }}
                >
                    <p>
                        You are about to pay :<br />
                        <strong
                            style={{
                                fontSize: '1.5em'
                            }}
                        >
                            {formatter.format(invoiceData.amount)}
                        </strong>
                    </p>
                </Container>
                <Container
                    style={{
                        margin: "1em 0",
                    }}
                >
                    <p>
                        1. Pay {formatter.format(invoiceData.amount)}, with MoMo the following code: <br />
                        <strong>*182*8*1*997474#</strong>
                    </p>
                </Container>
                <Divider />
                <Container
                    style={{
                        margin: "1em 0",
                        width: "90%",
                    }}
                >
                    <p>
                        2. Please enter the transaction ID you received by text after paying with the MoMo code above:
                    </p>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <p
                            style={{
                                fontStyle: "italic",
                                fontSize: "1.2em",
                            }}
                        >TxId:</p>
                        <Form.Input
                            placeholder="Transaction ID"
                            value={transactionId}
                            type="number"
                            onChange={(e) => setTransactionId(e.target.value)}
                            required
                        />
                    </div>
                    <Form.Checkbox
                        label="I agree that I have paid the amount above using the MoMo code provided and provided the correct transaction ID."
                        onChange={() => setPerformedPayment(!performedPayment)}
                        required
                    />
                    <Button
                        primary
                        type="submit"
                        content="Confirm Payment"
                        style={{
                            marginTop: '1em'
                        }}
                        disabled={!performedPayment || transactionId.length === 0}
                    />
                </Container>
            </Container>
        </Form>
    );
}


export function MoMoInvoicePaymentPage() {
    const { businessid } = useParams();
    const { invoiceid } = useParams();
    const { setCurrentBusiness } = useBusinessContext();
    const { currentUser } = useGeneralContext();

    useEffect(() => {
        if (businessid) {
            setCurrentBusiness(businessid)
        }
    }, [businessid, setCurrentBusiness]);

    const {
        data: momoData,
        isLoading: momoDataLoading,
    } = useQuery({
        queryKey: ["momo-client-intent", invoiceid],
        queryFn: async () => {
            if (!currentUser) return null;
            if (!invoiceid) return null;
            const idToken = await currentUser.getIdToken();
            return await getMoMoPaymentIntent(invoiceid, idToken);
        },
        retry: false,
        enabled: !!currentUser
    });

    const showPaymentForm = !momoDataLoading && (!momoData || !["succeeded", "completed", "pending"].includes(momoData.status));

    return (
        <ResponsiveContainer>
            {momoDataLoading ? <p>Loading...</p> : null}
            {showPaymentForm ?
                <MoMoPaymentForm
                    businessId={businessid}
                    invoiceId={invoiceid}
                /> : null
            }
            {momoData && momoData.status === "pending" ?
                <Segment basic
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "min(100%, 500px)",
                        margin: "auto"
                    }}
                >
                    <Message
                        info
                        style={{
                            width: "min(100%, 500px)",
                        }}
                    >
                        <Message.Header>Payment Pending</Message.Header>
                        <p>Your payment is being processed. Please wait for a few days.</p>
                    </Message>
                </Segment>
                : null}
            {momoData && ["succeeded", "completed"].includes(momoData.status) ?
                <Segment basic
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "min(100%, 500px)",
                        margin: "auto"
                    }}
                >
                    <Message
                        positive
                        style={{
                            width: "min(100%, 500px)",
                        }}
                    >
                        <Message.Header>Payment Succeeded</Message.Header>
                        <p>Your payment has been successfully processed.</p>
                    </Message>
                </Segment>
                : null}
        </ResponsiveContainer>
    )
}